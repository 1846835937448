import React from 'react';
import {
	RouterProvider,
	createBrowserRouter,
	redirect,
} from 'react-router-dom';
// import Song from './components/Song';
import MedicineTracker from './components/MedicineTracker';
import LandingPage from './components/LandingPage';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import Verify from './components/auth/Verify/Verify';
import { useUser } from './context/UserContext';
import MainLayout from './layout/MainLayout';

import Auth, { ACCESS_TOKEN_KEY } from './utils/auth';
import Calendar from './components/Calendar/Calendar';
import IntakeList from './components/IntakeList/IntakeList';
import Graphs from './components/Emotions/Graphs';

export default function Router() {
	const routes = [
		{
			path: '/',
			element: <MainLayout />,

			children: [
				{ path: '/', element: <LandingPage /> },
				{ path: 'signin', element: <Login /> },
				{ path: 'signup', element: <Signup /> },
				{ path: 'verify', element: <Verify /> },
			],
		},
		{
			path: '/',
			element: <MainLayout />,
			loader: async () => {
				const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
				if (token) {
					const { user } = await Auth.getUser(token);
					if (user && !user.isVerified) return redirect('/verify');
					return null; // allow through access
				}
				return redirect('/signin');
			},
			children: [
				{ path: 'record', element: <MedicineTracker /> },
				{ path: 'monthly', element: <Calendar /> },
				{ path: 'list', element: <IntakeList /> },
				{ path: 'graphs', element: <Graphs /> },
			],
		},
		{ '*': { element: <h1>Not Found</h1> } },
	];
	const { user, isLoggedIn } = useUser();
	const router = createBrowserRouter([...routes]);
	return <RouterProvider router={router} />;
}
