import React, { useEffect } from 'react';
import client from '../../api/client';
import EmotionDistributionGraph from './EmotionDistributionGraph';
import EmotionTrendsGraph from './EmotionTrendsGraph';
import Spinner from '../Global/Spinner';
export default function Graphs() {
	const [emotionData, setEmotionData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const fetchData = async () => {
		setLoading(true);
		const response = await client.get('/meds');
		const data = response.data;
		setEmotionData(data);
		setLoading(false);
	};
	useEffect(() => {
		fetchData();
	}, []);
	if (loading) return <Spinner />;
	return (
		<div>
			<div className='container mx-auto'>
				<h1 className='text-3xl font-bold text-center my-6'>
					Emotion Analytics
				</h1>
				{!loading && emotionData && (
					<div>
						<EmotionDistributionGraph emotionData={emotionData} />
						<EmotionTrendsGraph emotionData={emotionData} />
					</div>
				)}
			</div>
		</div>
	);
}
