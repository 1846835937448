import React, { useState } from 'react';
import MedicineIntakeForm from './MedicineIntakeForm';
import { useNavigate } from 'react-router-dom';

const MedicineTracker = () => {
	const [showIntakeForm, setShowIntakeForm] = useState(false);
	const navigate = useNavigate();

	return (
		<div>
			{showIntakeForm ? (
				<MedicineIntakeForm
					onClose={() => {
						setShowIntakeForm(false);
						navigate('/monthly');
					}}
				/>
			) : (
				<div className='bg-gray-50 py-24 sm:py-32'>
					<div className='mx-auto max-w-4xl px-6 lg:px-8'>
						<h2 className='text-center text-4xl font-semibold text-indigo-600'>
							Medicine Tracker
						</h2>
						<button
							onClick={() => setShowIntakeForm(true)}
							className='mt-10 w-full rounded-lg bg-indigo-600 py-4 px-6 text-2xl font-semibold text-white shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
						>
							Add Medicine Intake
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default MedicineTracker;
