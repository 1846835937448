import React, { useEffect, useState } from 'react';
import client from '../../api/client';
import formatDate from '../../utils/formatDate';
export default function DetailsHover({ title, day }) {
	const [details, setDetails] = useState(null);
	const [timeDifferences, setTimeDifferences] = useState([]);

	const fetchDay = async () => {
		try {
			const { data } = await client.get(`/meds/intake/${day.date}`);
			if (data) {
				console.log({ data });
				// const differences = calculateTimeBetweenIntakes(details);
				// setTimeDifferences(differences);
				setDetails(data);
			}
		} catch (error) {
			// Handle the error appropriately
			console.error('Error fetching details:', error);
		}
	};
	const calculateTimeBetweenIntakes = (intakes) => {
		const timeDifferences = [];

		for (let i = 0; i < intakes.length - 1; i++) {
			const currentIntakeTime = new Date(intakes[i].intakeTime);
			const nextIntakeTime = new Date(intakes[i + 1].intakeTime);

			const diff = nextIntakeTime - currentIntakeTime;
			const hours = Math.floor(diff / (1000 * 60 * 60));
			const minutes = Math.floor((diff / (1000 * 60)) % 60);

			timeDifferences.push(`${hours}h ${minutes}m`);
		}

		return timeDifferences;
	};

	const boldTime = (formatted) => {
		const parts = formatted.split('at');
		const date = parts[0];
		const time = parts[1];

		// Return the formatted string with the time in bold
		return (
			<span>
				{date} at <b>{time}</b>
			</span>
		);
	};

	useEffect(() => {
		fetchDay();
	}, [day]); // Fetch details when day changes

	return (
		<div className='absolute -top-16 left-0 z-10 w-64 bg-white shadow sm:rounded-lg'>
			<div className='px-4 py-5 sm:p-6'>
				<div className='sm:flex sm:items-start sm:justify-between'>
					<div>
						<h3 className='text-base font-semibold leading-6 text-gray-900'>
							Intakes: <b>{details && details.length}</b>
						</h3>
						<div className='mt-2 max-w-xl text-sm text-gray-500'>
							{details ? (
								<div>
									{details.map((intake, index) => {
										return (
											<div key={intake._id}>
												<p>
													{boldTime(
														formatDate(intake.intakeTime, {
															monthLength: 'short',
															dateSuffix: true,
														})
													)}
													{/* {index < timeDifferences.length && (
														<span>
															{' '}
															- Next intake in {timeDifferences[index]}
														</span>
													)} */}
												</p>
											</div>
										);
									})}

									{/* <p>{JSON.stringify(details, null, 2)}</p> */}
								</div>
							) : (
								<p>Loading...</p>
							)}
						</div>
					</div>
					{/* Other elements if needed */}
				</div>
			</div>
		</div>
	);
}
