import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const EmotionDistributionGraph = ({ emotionData }) => {
	const emotionCounts = emotionData.reduce((acc, curr) => {
		const emotion = curr.emotion;
		acc[emotion] = (acc[emotion] || 0) + 1;
		return acc;
	}, {});

	const data = {
		labels: Object.keys(emotionCounts),
		datasets: [
			{
				label: 'Emotion Distribution',
				data: Object.values(emotionCounts),
				backgroundColor: Object.keys(emotionCounts).map((emotion) =>
					getColor(emotion)
				),
			},
		],
	};

	return (
		<div className='p-4'>
			<h2 className='text-xl font-bold mb-4'>Emotion Distribution</h2>
			<Pie data={data} />
		</div>
	);
};

// Helper function to get color based on emotion
const getColor = (emotion) => {
	const emotionColors = {
		// Happy
		Happy: '#F4A261',
		Playful: '#F4A261',
		Aroused: '#F4A261',
		Cheeky: '#F4A261',
		Mischievous: '#F4A261',
		Lighthearted: '#F4A261',
		Jovial: '#F4A261',
		Whimsical: '#F4A261',
		Content: '#F4A261',
		Free: '#F4A261',
		Joyful: '#F4A261',
		Satisfied: '#F4A261',
		'At Ease': '#F4A261',
		Comfortable: '#F4A261',
		Fulfilled: '#F4A261',
		Interested: '#F4A261',
		Curious: '#F4A261',
		Inquisitive: '#F4A261',
		Engaged: '#F4A261',
		Absorbed: '#F4A261',
		Intrigued: '#F4A261',
		Fascinated: '#F4A261',
		Proud: '#F4A261',
		Confident: '#F4A261',
		Successful: '#F4A261',
		Accomplished: '#F4A261',
		Empowered: '#F4A261',
		Triumphant: '#F4A261',
		'Self-Assured': '#F4A261',
		Accepted: '#F4A261',
		Respected: '#F4A261',
		Valued: '#F4A261',
		Included: '#F4A261',
		Acknowledged: '#F4A261',
		Appreciated: '#F4A261',
		Welcomed: '#F4A261',
		Powerful: '#F4A261',
		Courageous: '#F4A261',
		Creative: '#F4A261',
		Influential: '#F4A261',
		Determined: '#F4A261',
		Dominant: '#F4A261',
		Authoritative: '#F4A261',
		Peaceful: '#F4A261',
		Loving: '#F4A261',
		Thankful: '#F4A261',
		Serene: '#F4A261',
		Calm: '#F4A261',
		Tranquil: '#F4A261',
		Harmonious: '#F4A261',
		Trustful: '#F4A261',
		Sensitive: '#F4A261',
		Intimate: '#F4A261',
		Reliable: '#F4A261',
		Faithful: '#F4A261',
		Secure: '#F4A261',
		Dependable: '#F4A261',
		Optimistic: '#F4A261',
		Hopeful: '#F4A261',
		Inspired: '#F4A261',
		Positive: '#F4A261',
		Upbeat: '#F4A261',
		Encouraged: '#F4A261',
		Elated: '#F4A261',
		Ecstatic: '#F4A261',
		Overjoyed: '#F4A261',
		Radiant: '#F4A261',
		Exhilarated: '#F4A261',
		Blissful: '#F4A261',
		Euphoric: '#F4A261',
		// Sad
		Sad: '#4A90E2',
		Lonely: '#4A90E2',
		Abandoned: '#4A90E2',
		Isolated: '#4A90E2',
		Disconnected: '#4A90E2',
		Solitary: '#4A90E2',
		Vulnerable: '#4A90E2',
		Victimized: '#4A90E2',
		Fragile: '#4A90E2',
		Exposed: '#4A90E2',
		Despair: '#4A90E2',
		Grief: '#4A90E2',
		Powerless: '#4A90E2',
		Guilty: '#4A90E2',
		Ashamed: '#4A90E2',
		Remorseful: '#4A90E2',
		Regretful: '#4A90E2',
		Contrite: '#4A90E2',
		Depressed: '#4A90E2',
		Empty: '#4A90E2',
		Inferior: '#4A90E2',
		Low: '#4A90E2',
		Hurt: '#4A90E2',
		Embarrassed: '#4A90E2',
		Disappointed: '#4A90E2',
		Wounded: '#4A90E2',
		Heartbroken: '#4A90E2',
		Devastated: '#4A90E2',
		Sorrowful: '#4A90E2',
		Nostalgic: '#4A90E2',
		Longing: '#4A90E2',
		Sentimental: '#4A90E2',
		// Angry
		Angry: '#FF4500',
		'Let Down': '#FF4500',
		Betrayed: '#FF4500',
		Resentful: '#FF4500',
		Mad: '#FF4500',
		Furious: '#FF4500',
		Jealous: '#FF4500',
		Aggressive: '#FF4500',
		Provoked: '#FF4500',
		Hostile: '#FF4500',
		Frustrated: '#FF4500',
		Annoyed: '#FF4500',
		Critical: '#FF4500',
		Distant: '#FF4500',
		// Bad
		Tired: '#7A7A7A',
		Unfocused: '#7A7A7A',
		Sleepy: '#7A7A7A',
		Exhausted: '#7A7A7A',
		Stressed: '#7A7A7A',
		Busy: '#7A7A7A',
		Overwhelmed: '#7A7A7A',
		Neglected: '#7A7A7A',
		// Fearful
		Fearful: '#A27EA8',
		Scared: '#A27EA8',
		Helpless: '#A27EA8',
		Anxious: '#A27EA8',
		Insecure: '#A27EA8',
		Weak: '#A27EA8',
		Rejected: '#A27EA8',
		Threatened: '#A27EA8',
		Dread: '#A27EA8',
		Petrified: '#A27EA8',
		Surprised: '#5BC0BE',
		Startled: '#5BC0BE',
		Shocked: '#5BC0BE',
		Confused: '#5BC0BE',
		Perplexed: '#5BC0BE',
		Amazed: '#5BC0BE',
		Astonished: '#5BC0BE',
		Excited: '#5BC0BE',
		Eager: '#5BC0BE',
		Intrigued: '#5BC0BE',
		Fascinated: '#5BC0BE',
		Confident: '#5BC0BE',
		Disgusted: '#6B8E23',
		Disapproving: '#6B8E23',
		Judgmental: '#6B8E23',
		Disappointed: '#6B8E23',
		Awful: '#6B8E23',
		Nauseated: '#6B8E23',
		Repelled: '#6B8E23',
		Horrified: '#6B8E23',
		Contempt: '#6B8E23',
		Skeptical: '#6B8E23',
	};

	return emotionColors[emotion] || '#000000';
};

export default EmotionDistributionGraph;
