import React, { useEffect, useState } from 'react';
import { useIntake } from '../../context/IntakeContext';
import IntakeListCard from './IntakeListCard';
import { adjustHexColor } from '../../utils/colorUtils';
import client from '../../api/client';
export default function IntakeList() {
	const { fetchRecords, records } = useIntake();
	useEffect(() => {
		fetchRecords();
	}, []);

	const [emotionWheel, setEmotionWheel] = useState([]);
	const getEmotionWheel = async () => {
		try {
			const { data } = await client.get('/emotions');
			console.log({ data });
			setEmotionWheel(data);
		} catch (error) {
			console.error({ error });
		}
	};

	useEffect(() => {
		getEmotionWheel();
	}, []);

	return (
		<div className='grid grid-cols-1 gap-4 lg:grid-cols-4 '>
			{records &&
				records.length > 0 &&
				records.map((record) => {
					function getColor() {
						// Recursive function to find the color
						function findEmotionColor(emotion, nodes) {
							for (const node of nodes) {
								// Check if current node matches the emotion
								if (node.feeling.toLowerCase() === emotion.toLowerCase()) {
									return node.color;
								}
								// Recurse into subs if present
								if (node.subs && node.subs.length > 0) {
									const foundColor = findEmotionColor(emotion, node.subs);
									if (foundColor) return foundColor;
								}
							}
							return null;
						}

						// Attempt to find the color
						const recordEmotion = record.emotion.toLowerCase();
						const colorFound =
							findEmotionColor(recordEmotion, emotionWheel) || '#E5E7EB'; // Default gray if no match

						// Adjust text color and update state
						const adjustedTextColor = adjustHexColor(colorFound, 50);
						{
							/* eColor=(`bg-[${colorFound}]`); */
						}
						{
							/* setTextColor(`text-[${adjustedTextColor}]`); */
						}
						return colorFound;
					}
					let eColor = getColor();
					return (
						<div key={record._id}>
							<IntakeListCard
								record={record}
								color={eColor}
								emotionWheel={emotionWheel}
							/>
						</div>
					);
				})}
		</div>
	);
}
