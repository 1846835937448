import {
	CalendarDaysIcon,
	CreditCardIcon,
	UserCircleIcon,
	DocumentTextIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import client from '../../api/client';
import { adjustHexColor } from '../../utils/colorUtils';

export default function IntakeListCard({
	emotionWheel,
	record,
	color: sColor,
}) {
	const [color, setColor] = useState(sColor || '');
	const [textColor, setTextColor] = useState('');

	return (
		<div className='lg:col-start-3 lg:row-end-1'>
			<h2 className='sr-only'>Summary</h2>
			<div
				style={{ color: '#fff' }}
				className={`rounded-lg shadow-sm ring-1 ring-gray-900/5`}
			>
				<dl className='flex flex-wrap'>
					<div className='flex-auto pl-6 pt-6'>
						<dt className='sr-only'>Title</dt>
						<dd className='mt-1 text-base font-semibold text-gray-900'>
							{new Date(record.intakeTime).toLocaleDateString()}
						</dd>
						<div className='flex-none self-end px-6 pt-4'>
							<label className='sr-only'>Emotion</label>
							<div
								style={{ backgroundColor: color, color: textColor }}
								className={`${color} text-white  text-sm inline-flex items-center rounded-md px-2 py-1  font-medium ring-1 ring-inset ring-gray-600/20`}
							>
								{record.emotion}
							</div>
						</div>
					</div>

					<div className='flex-none self-end px-6 pt-4'>
						<dt className='sr-only'>Took It</dt>
						<dd
							className={
								record.tookIt
									? `inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20`
									: `inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20`
							}
						>
							{record.tookIt ? 'Took it' : 'Did Not Take'}
						</dd>
					</div>

					<div className='mt-4 flex w-full flex-none gap-x-4 px-6'>
						<dt className='flex-none'>
							<span className='sr-only'>Due date</span>
							<CalendarDaysIcon
								aria-hidden='true'
								className='h-6 w-5 text-gray-400'
							/>
						</dt>
						<dd className='text-sm/6 text-gray-500'>
							<time dateTime={record.intakeTime}>
								{new Date(record.intakeTime).toLocaleTimeString()}
							</time>
						</dd>
					</div>
					<div className='mt-4 flex w-full flex-none gap-x-4 px-6 pb-5'>
						<dt className='flex-none'>
							<span className='sr-only'>Status</span>
							<DocumentTextIcon
								aria-hidden='true'
								className='h-6 w-5 text-gray-400'
							/>
						</dt>
						<dd className='text-sm/6 text-gray-500'>{record.note}</dd>
					</div>
				</dl>
			</div>
		</div>
	);
}
