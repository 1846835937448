import React from 'react';
import { Link } from 'react-router-dom';
export default function LandingPage() {
	return (
		<div className='min-h-screen bg-gray-100 flex flex-col justify-center items-center'>
			<h1 className='text-6xl font-bold text-gray-800 mb-4'>
				THIS CONTROLS YOUR LIFE!
			</h1>
			<div className='mt-8'>
				<Link
					to='/record'
					className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
				>
					Record
				</Link>
				<Link
					to='/monthly'
					className='ml-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'
				>
					Monthly
				</Link>
			</div>
		</div>
	);
}
