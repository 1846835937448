import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const EmotionTrendsGraph = ({ emotionData }) => {
	// Assign scores to emotions
	const emotionScores = {
		Happy: 1,
		Sad: -1,
		Angry: -2,
		Tired: -3,
		Fearful: -4,
		Surprised: 0, // Neutral emotions
		Disgusted: -5,
	};

	// Fallback for undefined emotions
	const getScore = (emotion) => emotionScores[emotion] || -6;

	// Format the labels based on the intake time
	const labels = emotionData.map((entry) =>
		new Date(entry.intakeTime).toLocaleDateString()
	);

	// Create the dataset using scores
	const data = {
		labels,
		datasets: [
			{
				label: 'Emotion Score Over Time',
				data: emotionData.map((entry) => getScore(entry.emotion)),
				borderColor: '#4A90E2', // Line color
				backgroundColor: 'rgba(74, 144, 226, 0.2)', // Line fill
				tension: 0.3,
			},
		],
	};

	return (
		<div className='p-4'>
			<h2 className='text-xl font-bold mb-4'>Emotion Trends Over Time</h2>
			<Line data={data} />
		</div>
	);
};

export default EmotionTrendsGraph;
