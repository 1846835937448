import React, { useState, useEffect } from 'react';

const EmotionSelector = ({ emotions, onEmotionSelect }) => {
	const [expandedStates, setExpandedStates] = useState({});
	const [selected, setSelected] = useState(null);
	const [filter, setFilter] = useState(''); // Track the filter input

	const colorMap = {
		Happy: '#F4A261',
		Sad: '#4A90E2',
		Angry: '#D1495B',
		Bad: '#7A7A7A',
		Fearful: '#A27EA8',
		Surprised: '#5BC0BE',
		Disgusted: '#6B8E23',
	};

	// Automatically expand all emotions when there is a filter value
	useEffect(() => {
		if (filter.trim()) {
			const allExpanded = {};
			emotions.forEach((emotion) => {
				allExpanded[emotion.feeling] = true;
				emotion.subs?.forEach((sub) => {
					allExpanded[sub.feeling] = true;
				});
			});
			setExpandedStates(allExpanded);
		} else {
			setExpandedStates({});
		}
	}, [filter, emotions]);

	const toggleEmotion = (feeling) => {
		setExpandedStates((prevStates) => ({
			...prevStates,
			[feeling]: !prevStates[feeling],
		}));
		setSelected(feeling);
	};

	const renderSubEmotions = (subs, parentFeeling, color) => {
		if (!subs || subs.length === 0) return null;

		return (
			<div className='mt-2 pl-6 space-y-2'>
				{subs.map((subEmotion) => (
					<div key={subEmotion.feeling}>
						<button
							className='w-full text-left p-2 rounded-lg text-gray-50 font-semibold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400'
							onClick={(e) => {
								e.preventDefault();
								toggleEmotion(subEmotion.feeling);
								onEmotionSelect({
									feeling: subEmotion.feeling,
									color: color,
								});
							}}
							style={{
								backgroundColor: color || '#cccccc',
								border:
									selected === subEmotion.feeling
										? '4px solid #000'
										: '4px solid transparent',
							}}
						>
							{subEmotion.feeling}
						</button>
						{expandedStates[subEmotion.feeling] &&
							renderSubEmotions(subEmotion.subs, subEmotion.feeling, color)}
					</div>
				))}
			</div>
		);
	};

	const filterEmotions = (emotions) => {
		return emotions
			.map((emotion) => {
				const filteredSubs =
					emotion.subs?.filter((sub) =>
						sub.feeling.toLowerCase().includes(filter.toLowerCase())
					) || [];

				if (
					emotion.feeling.toLowerCase().includes(filter.toLowerCase()) ||
					filteredSubs.length > 0
				) {
					return { ...emotion, subs: filteredSubs };
				}
				return null;
			})
			.filter(Boolean);
	};

	const filteredEmotions = filterEmotions(emotions);

	return (
		<div className='p-6 bg-gray-50 rounded-lg shadow-lg'>
			<h2 className='text-2xl font-bold text-gray-800 mb-6 text-center'>
				How are you feeling today?
			</h2>
			<input
				type='text'
				placeholder='Filter emotions...'
				value={filter}
				onChange={(e) => setFilter(e.target.value)}
				className='w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400'
			/>
			<div className='space-y-4'>
				{filteredEmotions.map((emotion) => (
					<div key={emotion.feeling}>
						<button
							className='w-full flex justify-between items-center p-4 rounded-lg shadow-md text-white font-bold transition-transform transform hover:scale-105 focus:scale-105 focus:ring-4 focus:ring-offset-2 focus:outline-none'
							style={{
								backgroundColor: colorMap[emotion.feeling] || '#cccccc',
							}}
							onClick={(e) => {
								e.preventDefault();
								toggleEmotion(emotion.feeling);
							}}
						>
							<span>{emotion.feeling}</span>
							<span>{expandedStates[emotion.feeling] ? '-' : '+'}</span>
						</button>
						{expandedStates[emotion.feeling] &&
							renderSubEmotions(
								emotion.subs,
								emotion.feeling,
								colorMap[emotion.feeling]
							)}
					</div>
				))}
			</div>
		</div>
	);
};

export default EmotionSelector;
