// Function to darken a hex color
function darkenHexColor(hex, percent) {
	if (hex[0] !== '#') hex = '#' + hex;

	let r = parseInt(hex.substring(1, 3), 16);
	let g = parseInt(hex.substring(3, 5), 16);
	let b = parseInt(hex.substring(5, 7), 16);

	r = Math.floor(r * (1 - percent / 100));
	g = Math.floor(g * (1 - percent / 100));
	b = Math.floor(b * (1 - percent / 100));

	r = Math.max(0, Math.min(255, r));
	g = Math.max(0, Math.min(255, g));
	b = Math.max(0, Math.min(255, b));

	return (
		'#' +
		r.toString(16).padStart(2, '0') +
		g.toString(16).padStart(2, '0') +
		b.toString(16).padStart(2, '0')
	);
}

// Function to lighten a hex color
function lightenHexColor(hex, percent) {
	if (hex[0] !== '#') hex = '#' + hex;

	let r = parseInt(hex.substring(1, 3), 16);
	let g = parseInt(hex.substring(3, 5), 16);
	let b = parseInt(hex.substring(5, 7), 16);

	r = Math.floor(r + (255 - r) * (percent / 100));
	g = Math.floor(g + (255 - g) * (percent / 100));
	b = Math.floor(b + (255 - b) * (percent / 100));

	r = Math.max(0, Math.min(255, r));
	g = Math.max(0, Math.min(255, g));
	b = Math.max(0, Math.min(255, b));

	return (
		'#' +
		r.toString(16).padStart(2, '0') +
		g.toString(16).padStart(2, '0') +
		b.toString(16).padStart(2, '0')
	);
}

// Function to determine whether to lighten or darken
function adjustHexColor(hex, percent) {
	if (hex[0] !== '#') hex = '#' + hex;
	const hexNoHash = hex.replace('#', '');
	let r = parseInt(hexNoHash.substring(1, 3), 16);
	let g = parseInt(hexNoHash.substring(3, 5), 16);
	let b = parseInt(hexNoHash.substring(5, 7), 16);

	// Calculate perceived brightness
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;

	// Adjust based on brightness (lighten if dark, darken if light)
	if (brightness < 128) {
		return lightenHexColor(hexNoHash, percent);
	} else {
		return darkenHexColor(hexNoHash, percent);
	}
}

export { darkenHexColor, lightenHexColor, adjustHexColor };
