import React, { useEffect, useState } from 'react';
import client from '../api/client';
import EmotionSelector from './EmotionWheel';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

const MedicineIntakeForm = ({ onClose }) => {
	const [intakeTime, setIntakeTime] = useState(new Date());
	const [note, setNote] = useState('');
	const [emotions, setEmotions] = useState([]);
	const [selectedEmotion, setSelectedEmotion] = useState('');
	const [selectedEmotionColor, setSelectedEmotionColor] = useState('');
	const [tookIt, setTookIt] = useState(false);
	const [showEmotionWheel, setShowEmotionWheel] = useState(false);

	// Fetch emotion options from the backend
	useEffect(() => {
		const fetchEmotions = async () => {
			try {
				const response = await client.get('/emotions'); // Replace with your endpoint
				setEmotions(response.data);
			} catch (error) {
				console.error('Failed to fetch emotions', error);
			}
		};
		fetchEmotions();
	}, []);

	useEffect(() => {
		console.log({
			note,
			selectedEmotion,
			tookIt,
			intakeTime,
			selectedEmotionColor,
		});
	}, [note, selectedEmotion, tookIt, intakeTime]);

	// Helper function to format the date for datetime-local input in local time
	const formatDateForLocalInput = (date) => {
		const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
		const localDate = new Date(date.getTime() - offset);
		return localDate.toISOString().slice(0, 16); // Format for datetime-local
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await client.post('/meds', {
				intakeTime,
				note,
				emotion: selectedEmotion,
				color: selectedEmotionColor,
				tookIt,
			});
			alert('Medicine intake recorded successfully!');
			onClose(); // Close the form after submission
		} catch (error) {
			console.error('Error recording medicine intake:', error);
		}
	};

	return (
		<div className='bg-gray-50 py-10 sm:py-10'>
			<div className='mx-auto max-w-4xl px-6 lg:px-8'>
				<h2 className='text-center text-4xl font-semibold text-indigo-600'>
					Record Medicine Intake
				</h2>
				<form
					className='mt-10 space-y-8 bg-white p-10 rounded-lg shadow-lg'
					onSubmit={handleSubmit}
				>
					{/* Intake Time */}
					<div>
						<label
							htmlFor='intakeTime'
							className='block text-2xl font-medium text-gray-800'
						>
							Intake Time
						</label>
						<input
							type='datetime-local'
							id='intakeTime'
							value={formatDateForLocalInput(intakeTime)}
							onChange={(e) => setIntakeTime(new Date(e.target.value))}
							className='mt-2 w-full rounded-lg border border-gray-300 p-4 text-xl shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
						/>
					</div>

					{/* Emotion */}
					<div>
						<div className='flex justify-between items-baseline'>
							<label
								htmlFor='emotion'
								className='block text-2xl font-medium text-gray-800'
							>
								Emotion{selectedEmotion ? ': ' + selectedEmotion : ''}
							</label>
							<button
								onClick={() => setShowEmotionWheel(!showEmotionWheel)}
								type='button'
								className='block text-2xl font-medium text-gray-800'
							>
								{showEmotionWheel ? (
									<EyeSlashIcon className='h-5 w-5' />
								) : (
									<EyeIcon className='h-5 w-5' />
								)}
							</button>
						</div>

						<div>
							{showEmotionWheel && (
								<EmotionSelector
									emotions={emotions}
									onEmotionSelect={(e) => {
										setSelectedEmotion(e.feeling);
										setSelectedEmotionColor(e.color);
									}}
								/>
							)}
						</div>
					</div>

					{/* Note */}
					<div>
						<label
							htmlFor='note'
							className='block text-2xl font-medium text-gray-800'
						>
							Note
						</label>
						<textarea
							id='note'
							value={note}
							onChange={(e) => setNote(e.target.value)}
							rows='5'
							className='mt-2 w-full rounded-lg border border-gray-300 p-4 text-xl shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
							placeholder='Add additional details or notes here...'
						></textarea>
					</div>

					{/* Took It */}
					<div className='flex items-center'>
						<input
							type='checkbox'
							id='tookIt'
							checked={tookIt}
							onChange={(e) => setTookIt(e.target.checked)}
							className='h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
						/>
						<label
							htmlFor='tookIt'
							className='ml-4 text-2xl font-medium text-gray-800'
						>
							Confirm that you took the medicine
						</label>
					</div>

					{/* Submit Button */}
					<div>
						<button
							type='submit'
							className='w-full rounded-lg bg-indigo-600 py-4 px-6 text-2xl font-semibold text-white shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
						>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default MedicineIntakeForm;
