import { Fragment } from 'react';
import {
	Disclosure,
	DisclosureButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Auth from '../utils/auth';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
	const { user, isLoggedIn } = useUser();
	const location = useLocation();
	const navigation = [
		{ name: 'Home', href: '/', current: location.pathname === '/' },
		{
			name: 'Record',
			href: '/record',
			current: location.pathname === '/record',
		},
		{
			name: 'Monthly',
			href: '/monthly',
			current: location.pathname === '/monthly',
		},
		{
			name: 'List',
			href: '/list',
			current: location.pathname === '/list',
		},
		{
			name: 'Graphs',
			href: '/graphs',
			current: location.pathname === '/graphs',
		},
	];
	return (
		<Disclosure
			as='nav'
			className='bg-gray-800'
		>
			{({ open }) => (
				<>
					<div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
						<div className='relative flex h-16 items-center justify-between'>
							<div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
								{/* Mobile menu button*/}
								<DisclosureButton className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
									<span className='absolute -inset-0.5' />
									<span className='sr-only'>Open main menu</span>
									{open ? (
										<XMarkIcon
											className='block h-6 w-6'
											aria-hidden='true'
										/>
									) : (
										<Bars3Icon
											className='block h-6 w-6'
											aria-hidden='true'
										/>
									)}
								</DisclosureButton>
							</div>
							<div className='flex flex-1 items-center justify-center sm:items-center sm:justify-start'>
								<div className='flex flex-shrink-0 items-center'>
									<Link to='/'>
										<h1 className='text-5xl font-bold text-gray-50 '>💊</h1>
									</Link>
								</div>
								<div className='hidden sm:ml-6 sm:block'>
									<div className='flex space-x-4'>
										{navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={classNames(
													item.current
														? 'bg-gray-900 text-white'
														: 'text-gray-300 hover:bg-gray-700 hover:text-white',
													'rounded-md px-3 py-2 text-sm font-medium'
												)}
												aria-current={item.current ? 'page' : undefined}
											>
												{item.name}
											</a>
										))}
									</div>
								</div>
							</div>
							<div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
								<button
									type='button'
									className='relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
								>
									<span className='absolute -inset-1.5' />
									<span className='sr-only'>View notifications</span>
									<BellIcon
										className='h-6 w-6'
										aria-hidden='true'
									/>
								</button>
								{/* Profile dropdown */}

								{user && isLoggedIn && (
									<Menu
										as='div'
										className='relative ml-3'
									>
										<div>
											<MenuButton className='relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
												<span className='absolute -inset-1.5' />
												<span className='sr-only'>Open user menu</span>
												<img
													className='h-8 w-8 rounded-full'
													src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
													alt=''
												/>
											</MenuButton>
										</div>

										<Transition
											as={Fragment}
											enter='transition ease-out duration-100'
											enterFrom='transform opacity-0 scale-95'
											enterTo='transform opacity-100 scale-100'
											leave='transition ease-in duration-75'
											leaveFrom='transform opacity-100 scale-100'
											leaveTo='transform opacity-0 scale-95'
										>
											<MenuItems className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
												<MenuItem>
													{({ active }) => (
														<a
															href='#'
															className={classNames(
																active ? 'bg-gray-100' : '',
																'block px-4 py-2 text-sm text-gray-700'
															)}
														>
															Your Profile
														</a>
													)}
												</MenuItem>
												<MenuItem>
													{({ active }) => (
														<a
															href='#'
															className={classNames(
																active ? 'bg-gray-100' : '',
																'block px-4 py-2 text-sm text-gray-700'
															)}
														>
															Settings
														</a>
													)}
												</MenuItem>
												{user && isLoggedIn && (
													<MenuItem>
														{({ active }) => (
															<button
																onClick={() => Auth.logout()}
																className={classNames(
																	active ? 'bg-gray-100' : '',
																	'block px-4 py-2 text-sm text-gray-700 w-full text-left'
																)}
															>
																Logout
															</button>
														)}
													</MenuItem>
												)}
											</MenuItems>
										</Transition>
									</Menu>
								)}
							</div>
						</div>
					</div>

					<Disclosure.Panel className='sm:hidden'>
						<div className='space-y-1 px-2 pb-3 pt-2'>
							{navigation.map((item) => (
								<Disclosure.Button
									key={item.name}
									as='a'
									href={item.href}
									className={classNames(
										item.current
											? 'bg-gray-900 text-white'
											: 'text-gray-300 hover:bg-gray-700 hover:text-white',
										'block rounded-md px-3 py-2 text-base font-medium'
									)}
									aria-current={item.current ? 'page' : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
